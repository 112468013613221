"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ColorAbbreviation = exports.ColorName = exports.ColorCode = void 0;
var ColorCode;
(function (ColorCode) {
    ColorCode["Black"] = "#000000";
    ColorCode["DarkGray"] = "#404040";
    ColorCode["Gray"] = "#808080";
    ColorCode["Silver"] = "#BFBFBF";
    ColorCode["White"] = "#FFFFFF";
    ColorCode["Yellow"] = "#FEFE00";
    ColorCode["Red"] = "#EE0000";
    ColorCode["Orange"] = "#FFA100";
    ColorCode["Blue"] = "#0000F2";
    ColorCode["Green"] = "#00D800";
    ColorCode["Purple"] = "#A83DD9";
    ColorCode["Pink"] = "#F33D7B";
    ColorCode["Transparent"] = "transparent";
})(ColorCode = exports.ColorCode || (exports.ColorCode = {}));
var ColorName;
(function (ColorName) {
    ColorName["Black"] = "black";
    ColorName["DarkGray"] = "darkGray";
    ColorName["Gray"] = "gray";
    ColorName["Silver"] = "silver";
    ColorName["White"] = "white";
    ColorName["Yellow"] = "yellow";
    ColorName["Red"] = "red";
    ColorName["Orange"] = "orange";
    ColorName["Blue"] = "blue";
    ColorName["Green"] = "green";
    ColorName["Purple"] = "purple";
    ColorName["Pink"] = "pink";
    ColorName["Transparent"] = "transparent";
})(ColorName = exports.ColorName || (exports.ColorName = {}));
var ColorAbbreviation;
(function (ColorAbbreviation) {
    ColorAbbreviation["Black"] = "n";
    ColorAbbreviation["DarkGray"] = "d";
    ColorAbbreviation["Gray"] = "l";
    ColorAbbreviation["Silver"] = "s";
    ColorAbbreviation["White"] = "w";
    ColorAbbreviation["Yellow"] = "y";
    ColorAbbreviation["Red"] = "r";
    ColorAbbreviation["Orange"] = "o";
    ColorAbbreviation["Blue"] = "b";
    ColorAbbreviation["Green"] = "g";
    ColorAbbreviation["Purple"] = "m";
    ColorAbbreviation["Pink"] = "p";
    ColorAbbreviation["Transparent"] = "t";
})(ColorAbbreviation = exports.ColorAbbreviation || (exports.ColorAbbreviation = {}));
